import SME4 from '../IMAGES/Images5/sme4.png';

const Cdata = [
    {
        id: 1,
        title: 'SME Food App Mobile Application',
        imgscr: SME4,
        reactnative: true,
        nodejs: true,
        mongodb: true,
        mysql: false,
        github: 'https://github.com/JAYSONRK/sme_project3',
        visit: 'https://jaysonrk.github.io/sme_project3/'
    }
]

export default Cdata;
