import SME1 from '../IMAGES/Images5/sme1.png';
import SME2 from '../IMAGES/Images5/sme2.png';
import SME3 from '../IMAGES/Images5/sme3.png';


const Bdata = [
    {
        id: 1,
        title: 'IoT Asset Tracking & Authentication System',
        imgscr: SME1,
        react: true,
        nodejs: true,
        mongodb: true,
        mysql: false,
        github: 'https://github.com/JAYSONRK/sme_project1',
        visit: 'https://jaysonrk.github.io/sme_project1/'
    },
    {
        id: 2,
        title: 'SME Food Project Admin Page',
        imgscr: SME2,
        react: true,
        nodejs: true,
        mongodb: true,
        mysql: false,
        github: 'https://github.com/JAYSONRK/sme_project2',
        visit: 'https://jaysonrk.github.io/sme_project2/'
    },
    {
        id: 3,
        title: 'SME Water Tank Height Monitoring Project',
        imgscr: SME3,
        react: true,
        nodejs: true,
        mongodb: false,
        mysql: true,
        github: 'https://github.com/JAYSONRK/sme_project4',
        visit: 'https://jaysonrk.github.io/sme_project4/'
    }
]

export default Bdata;
