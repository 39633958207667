import A from '../IMAGES/Images1/a.jpg';
import B from '../IMAGES/Images1/b.jpg';
import C from '../IMAGES/Images1/c.jpg';
import D from '../IMAGES/Images1/d.jpg';
import E from '../IMAGES/Images1/e.jpg';
import F from '../IMAGES/Images1/f.jpg';
import G from '../IMAGES/Images1/g.jpg';
import H from '../IMAGES/Images1/h.jpg';
import I from '../IMAGES/Images1/i.jpg';
import J from '../IMAGES/Images1/j.jpg';
import K from '../IMAGES/Images1/k.jpg';
import L from '../IMAGES/Images1/l.jpg';
import M from '../IMAGES/Images1/m.jpg';
import N from '../IMAGES/Images1/n.png';
import RA from '../IMAGES/Images2/ra.jpg';
import RB from '../IMAGES/Images2/rb.jpg';
import RC from '../IMAGES/Images2/rc.jpg';
import RD from '../IMAGES/Images2/rd.jpg';
import RE from '../IMAGES/Images2/re.jpg';
import RF from '../IMAGES/Images2/rf.jpg';
import RG from '../IMAGES/Images2/rg.jpg';
import RH from '../IMAGES/Images2/rh.jpg';
import RI from '../IMAGES/Images2/ri.png';
import RJ from '../IMAGES/Images2/rj.jpg';
import RK from '../IMAGES/Images2/rk.jpg';
import RL from '../IMAGES/Images2/rl.jpg';
import RM from '../IMAGES/Images2/rm.jpg';
import RN from '../IMAGES/Images2/rn.jpg';
// import BA from '../IMAGES/Images3/ba.png';
import BB from '../IMAGES/Images3/bb.jpg';
import BC from '../IMAGES/Images3/bc.jpg';
import BD from '../IMAGES/Images3/bd.jpg';
import BE from '../IMAGES/Images3/be.jpg';
import AA from '../IMAGES/Images4/aa.png';
import AB from '../IMAGES/Images4/ab.png';
import AC from '../IMAGES/Images4/ac.png';
import AD from '../IMAGES/Images4/ad.jpg';
import AE from '../IMAGES/Images4/ae.jpg';
import AF from '../IMAGES/Images4/af.jpg';
import AG from '../IMAGES/Images4/ag.jpg';
import AH from '../IMAGES/Images4/ah.png';
import AI from '../IMAGES/Images4/ai.png';
import AJ from '../IMAGES/Images4/aj.png';


const Adata = [
    {
        id: 1,
        title: 'Interactive rating component main',
        imgscr: A,
        js: true,
        api: false,
        github: 'https://github.com/JAYSONRK/Interactive-rating-component.git',
        visit: 'https://jaysonrk.github.io/Interactive-rating-component/'
    },
    {
        id: 2,
        title: 'Huddle landing page ',
        imgscr: B,
        js: false,
        api: false,
        github: 'https://github.com/JAYSONRK/Huddle-landing-page-with-a-single-introductory-section.git',
        visit: 'https://jaysonrk.github.io/Huddle-landing-page-with-a-single-introductory-section/'
    },
    {
        id: 3,
        title: 'Four card feature section master',
        imgscr: C,
        js: false,
        api: false,
        github: 'https://github.com/JAYSONRK/Four-card-feature-section.git',
        visit: 'https://jaysonrk.github.io/Four-card-feature-section/'
    },
    {
        id: 4,
        title: 'Social proof section master',
        imgscr: D,
        js: false,
        api: false,
        github: 'https://github.com/JAYSONRK/Social-proof-section.git',
        visit: 'https://jaysonrk.github.io/Social-proof-section/'
    },
    {
        id: 5,
        title: '3 column preview card component',
        imgscr: E,
        js: false,
        api: false,
        github: 'https://github.com/JAYSONRK/3-column-preview-card-component.git',
        visit: 'https://jaysonrk.github.io/3-column-preview-card-component/'
    },
    {
        id: 6,
        title: 'Profile card component',
        imgscr: F,
        js: false,
        api: false,
        github: 'https://github.com/JAYSONRK/Profile-card-component.git',
        visit: 'https://jaysonrk.github.io/Profile-card-component/'
    },
    {
        id: 7,
        title: 'Stats preview card component main',
        imgscr: G,
        js: false,
        api: false,
        github: 'https://github.com/JAYSONRK/Stats-preview-card-component.git',
        visit: 'https://jaysonrk.github.io/Stats-preview-card-component/'
    },
    {
        id: 8,
        title: 'Nft preview card component',
        imgscr: H,
        js: false,
        api: false,
        github: 'https://github.com/JAYSONRK/NFT-preview-card-component.git',
        visit: 'https://jaysonrk.github.io/NFT-preview-card-component/'
    },
    {
        id: 9,
        title: 'results summary component',
        imgscr: I,
        js: false,
        api: false,
        github: 'https://github.com/JAYSONRK/Results-summary-component.git',
        visit: 'https://jaysonrk.github.io/Results-summary-component/'
    },
    {
        id: 10,
        title: 'order summary component',
        imgscr: J,
        js: false,
        api: false,
        github: 'https://github.com/JAYSONRK/Order-summary-component.git',
        visit: 'https://jaysonrk.github.io/Order-summary-component/'
    },
    {
        id: 11,
        title: 'Single price grid component',
        imgscr: K,
        js: false,
        api: false,
        github: 'https://github.com/JAYSONRK/Single-price-grid-component.git',
        visit: 'https://jaysonrk.github.io/Single-price-grid-component/'
    },
    {
        id: 12,
        title: 'Product preview card component',
        imgscr: L,
        js: false,
        api: false,
        github: 'https://github.com/JAYSONRK/Product-preview-card-component.git',
        visit: 'https://jaysonrk.github.io/Product-preview-card-component/'
    },
    {
        id: 13,
        title: 'QR scanner code',
        imgscr: M,
        js: false,
        api: false,
        github: 'https://github.com/JAYSONRK/QR-code-component.git',
        visit: 'https://jaysonrk.github.io/QR-code-component/'
    },
    {
        id: 14,
        title: 'Page increment counter',
        imgscr: N,
        js: true,
        api: false,
        github: 'https://github.com/JAYSONRK/Page-increment-counter.git',
        visit: 'https://jaysonrk.github.io/Page-increment-counter/'
    },
    {
        id: 1,
        title: 'Crowdfunding product page-main',
        imgscr: RA,
        js: true,
        api: false,
        github: 'https://github.com/JAYSONRK/Crowdfunding-product-page_netlify.git',
        visit: 'https://spontaneous-entremet-400850.netlify.app/'
    },
    {
        id: 2,
        title: 'Time tracking dashboard main',
        imgscr: RB,
        js: true,
        api: false,
        github: 'https://github.com/JAYSONRK/Time-tracking-dashboard_netlify.git',
        visit: 'https://genuine-cajeta-d12c8a.netlify.app/'
    },
    {
        id: 3,
        title: 'Intro section with dropdown main',
        imgscr: RC,
        js: true,
        api: false,
        github: 'https://github.com/JAYSONRK/Intro-section-with-dropdown-navigation_netlify.git',
        visit: 'https://stirring-profiterole-6b0a66.netlify.app/'
    },
    {
        id: 4,
        title: 'Expenses chart component main',
        imgscr: RD,
        js: true,
        api: false,
        github: 'https://github.com/JAYSONRK/Expenses-chart-component_netlify.git',
        visit: 'https://stalwart-sorbet-e68281.netlify.app/'
    },
    {
        id: 5,
        title: 'Interactive card details form main',
        imgscr: RE,
        js: true,
        api: false,
        github: 'https://github.com/JAYSONRK/Interactive-card-details-form_netlify.git',
        visit: 'https://lambent-cannoli-3d8494.netlify.app/'
    },
    {
        id: 6,
        title: 'Notifications page main',
        imgscr: RF,
        js: true,
        api: false,
        github: 'https://github.com/JAYSONRK/Notifications-page_netlify.git',
        visit: 'https://snazzy-tarsier-5e6164.netlify.app/'
    },
    {
        id: 7,
        title: 'News homepage main',
        imgscr: RG,
        js: true,
        api: false,
        github: 'https://github.com/JAYSONRK/News-homepage_netlify.git',
        visit: 'https://bespoke-truffle-64f2ea.netlify.app/'
    },
    {
        id: 8,
        title: 'newsletter sign up with success main',
        imgscr: RH,
        js: true,
        api: false,
        github: 'https://github.com/JAYSONRK/Newsletter-sign-up-form-with-success-message_netlify.git',
        visit: 'https://tiny-griffin-d19d89.netlify.app/'
    },
    {
        id: 9,
        title: 'netflix show list',
        imgscr: RI,
        js: true,
        api: false,
        github: 'https://github.com/JAYSONRK/Netflix-show-list_netlify.git',
        visit: 'https://frabjous-conkies-194bef.netlify.app/'
    },
    {
        id: 10,
        title: 'Intro component with signup form master',
        imgscr: RJ,
        js: true,
        api: false,
        github: 'https://github.com/JAYSONRK/JAYSONRK-Intro_component_with_sign-up_form_netlify.git',
        visit: 'https://famous-banoffee-90f6ae.netlify.app/'
    },
    {
        id: 11,
        title: 'Faq accordion card main',
        imgscr: RK,
        js: true,
        api: false,
        github: 'https://github.com/JAYSONRK/JAYSONRK-FAQ_accordion_card_netlify.git',
        visit: 'https://wondrous-stardust-77dcd6.netlify.app/'
    },
    {
        id: 12,
        title: 'Article preview component master',
        imgscr: RL,
        js: true,
        api: false,
        github: 'https://github.com/JAYSONRK/Article_preview_component_netlify.git',
        visit: 'https://inquisitive-starlight-7d8aa3.netlify.app/'
    },
    {
        id: 13,
        title: 'Ping coming soon page master',
        imgscr: RM,
        js: true,
        api: false,
        github: 'https://github.com/JAYSONRK/Ping-single-column-coming-soon-page_netlify.git',
        visit: 'https://rococo-mooncake-626ae0.netlify.app/'
    },
    {
        id: 14,
        title: 'Base apparel coming soon master',
        imgscr: RN,
        js: true,
        api: false,
        github: 'https://github.com/JAYSONRK/Base_Apparel_coming_soon_page_netlify.git',
        visit: 'https://deft-centaur-30397e.netlify.app/'
    },
    // {
    //     id: 1,
    //     title: 'Jayson bootstrap blog main',
    //     imgscr: BA,
    //     js: false,
    //     api: false,
    //     github: 'https://github.com/JAYSONRK/Jayson-Progamming-language-Blog-bootstrap.git',
    //     visit: 'https://jaysonrk.github.io/Jayson-Progamming-language-Blog-bootstrap/'
    // },
    {
        id: 2,
        title: 'loopstudios landing page main',
        imgscr: BB,
        js: true,
        api: false,
        github: 'https://github.com/JAYSONRK/Loopstudios-landing-page_netlify.git',
        visit: 'https://lucky-stroopwafel-bc9ad3.netlify.app/'
    },
    {
        id: 3,
        title: 'blogr landing page main',
        imgscr: BC,
        js: true,
        api: false,
        github: 'https://github.com/JAYSONRK/Blogr-landing-page_netlify.git',
        visit: 'https://darling-macaron-96ca8e.netlify.app/'
    },
    {
        id: 4,
        title: 'sunnyside agency landing page main',
        imgscr: BD,
        js: true,
        api: false,
        github: 'https://github.com/JAYSONRK/Sunnyside-agency-landing-page_netlify.git',
        visit: 'https://harmonious-concha-c7aa23.netlify.app/'
    },
    {
        id: 5,
        title: 'testimonials grid section main',
        imgscr: BE,
        js: false,
        api: false,
        github: 'https://github.com/JAYSONRK/Testimonials-grid-section_netlify.git',
        visit: 'https://silly-mousse-dec468.netlify.app/'
    },
    {
        id: 1,
        title: 'jayson keep (clone) app',
        imgscr: AA,
        js: true,
        api: false,
        github: 'https://github.com/JAYSONRK/Jayson-keep-clone-app_netlify.git',
        visit: 'https://aquamarine-kelpie-23bb70.netlify.app/'
    },
    {
        id: 2,
        title: 'todo list app',
        imgscr: AB,
        js: true,
        api: false,
        github: 'https://github.com/JAYSONRK/TODO-list_netlify.git',
        visit: 'https://whimsical-cheesecake-011b92.netlify.app/'
    },
    {
        id: 3,
        title: 'weather app',
        imgscr: AC,
        js: true,
        api: true,
        github: 'https://github.com/JAYSONRK/Weather-app-API_netlify.git',
        visit: 'https://inspiring-entremet-5dcb64.netlify.app/'
    },
    {
        id: 4,
        title: 'age calculator app',
        imgscr: AD,
        js: true,
        api: false,
        github: 'https://github.com/JAYSONRK/Age-calculator-app_netlify.git',
        visit: 'https://sprightly-torrone-45aaa4.netlify.app/'
    },
    {
        id: 5,
        title: 'tip calculator app ',
        imgscr: AE,
        js: true,
        api: false,
        github: 'https://github.com/JAYSONRK/Tip-calculator-app_netlify.git',
        visit: 'https://glistening-rolypoly-504687.netlify.app/'
    },
    {
        id: 6,
        title: 'interactive pricing component app',
        imgscr: AF,
        js: true,
        api: false,
        github: 'https://github.com/JAYSONRK/Interactive-pricing-component_netlify.git',
        visit: 'https://chic-hotteok-b26d8a.netlify.app/'
    },
    {
        id: 7,
        title: 'advice generator app',
        imgscr: AG,
        js: true,
        api: true,
        github: 'https://github.com/JAYSONRK/Advice-generator-app_netlify.git',
        visit: 'https://elaborate-fairy-d7a364.netlify.app/'
    },
    {
        id: 8,
        title: 'meme generator app',
        imgscr: AH,
        js: true,
        api: true,
        github: 'https://github.com/JAYSONRK/Meme-Generator-API.git',
        visit: 'https://jaysonrk.github.io/Meme-Generator-API/'
    },
    {
        id: 9,
        title: 'photo API app',
        imgscr: AI,
        js: true,
        api: true,
        github: 'https://github.com/JAYSONRK/Photo-app-API.git',
        visit: 'https://jaysonrk.github.io/Photo-app-API/'
    },
    {
        id: 10,
        title: 'flag API app',
        imgscr: AJ,
        js: true,
        api: true,
        github: 'https://github.com/JAYSONRK/INDIA-API-PROJECT.git',
        visit: 'https://jaysonrk.github.io/INDIA-API-PROJECT/'
    }
]

export default Adata;